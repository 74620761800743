import React, {useState} from "react"

import Layout from "../components/layout"
import {Formik} from "formik";
import {Link} from "gatsby";
import {userPool} from "../utils/cognito-utils";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import SEO from "../components/seo";

const Page = () => {
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState(null)
  return (
    <Layout>
      <SEO title="Reset password"/>
      <div className="font-title text-xl uppercase mt-16 mb-6 text-center tracking-wide">Reset your password</div>
      {{
        1: <Formik
          key='step2'
          initialValues={
            {
              email: ''
            }
          }
          validate={values => {
            let errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }
          }
          onSubmit={async (input, {setSubmitting, setStatus}) => {
            const {email} = input
            var userData = {
              Username: email,
              Pool: userPool
            };
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
            cognitoUser.forgotPassword({
              onSuccess: () => {
                setSubmitting(false);
                setEmail(email)
                setStep(2)
              },
              onFailure: err => {
                console.error(err)
                setStatus({msg: err.message});
                setSubmitting(false);
              },
            })
          }
          }
        >
          {
            ({
               values,
               errors,
               touched,
               status,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
             }) => (
              <form className='w-full max-w flex flex-col items-center content-center pb-2 px-2 md:px-64'
                    onSubmit={handleSubmit}>
                <div className="mb-4 w-full">
                  <input
                    className='focus:outline-none w-full border-solid border-2 border-black p-2'
                    type="email"
                    name="email"
                    placeholder='Email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <p className="text-red-700 text-xs italic">
                    {errors.email && touched.email && errors.email}
                  </p>
                </div>
                <p className="text-red-700 text-xs italic">
                  {status && status.msg}
                </p>
                <button
                  className='w-full bg-black font-title tracking-wide text-white py-3'
                  type="submit" disabled={isSubmitting}>
                  Reset
                </button>
              </form>
            )
          }
        </Formik>,
        2: <>
          <div className='font-sans font-light text-center mb-2'>You will receive an email with a code to change
            your password.
          </div>
          < Formik
            key='step1'
            initialValues={
              {
                code: '', password: ''
              }
            }
            validate={values => {
              let errors = {};
              if (!values.code) {
                errors.code = 'Required';
              }
              if (!values.password) {
                errors.password = 'Required';
              }
              return errors;
            }
            }
            onSubmit={async (input, {setSubmitting, setStatus}) => {
              const {code, password} = input
              var userData = {
                Username: email,
                Pool: userPool
              };
              const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
              cognitoUser.confirmPassword(code, password, {
                onSuccess: () => {
                  setSubmitting(false);
                  setStep(3)
                },
                onFailure: err => {
                  console.error(err)
                  setStatus({msg: err.message});
                  setSubmitting(false);
                },
              })
            }
            }
          >
            {
              ({
                 values,
                 errors,
                 touched,
                 status,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
               }) => (
                <form className='w-full max-w flex flex-col items-center content-center pb-2 px-2 md:px-64'
                      onSubmit={handleSubmit}>
                  <div className="mb-4 w-full">
                    <input
                      className='focus:outline-none w-full border-solid border-2 border-black p-2'
                      type="text"
                      name="code"
                      placeholder='Code'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                    />
                    <p className="text-red-700 text-xs italic">
                      {errors.code && touched.code && errors.code}
                    </p>
                  </div>
                  <div className="mb-4 w-full">
                    <input
                      className='focus:outline-none w-full border-solid border-2 border-black p-2'
                      type="password"
                      name="password"
                      placeholder='New password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <p className="text-red-700 text-xs italic">
                      {errors.password && touched.password && errors.password}
                    </p>
                  </div>
                  <p className="text-red-700 text-xs italic">
                    {status && status.msg}
                  </p>
                  <button
                    className='w-full bg-black font-title tracking-wide text-white py-3'
                    type="submit" disabled={isSubmitting}>
                    Reset
                  </button>
                </form>
              )
            }
          </Formik></>,
        3: <div className='flex flex-col justify-between items-center'>
          <div className='font-sans font-light text-center mb-2'>Your password was successfully reset.</div>
          <div className='mt-4 mb-2'>
            <Link
              className='text-xl tracking-wide font-title no-underline bg-black text-white py-2 px-8 focus:outline-none'
              to='/'>
              Continue
            </Link>
          </div>

        </div>
      }[step]}
      <div className={'flex justify-center mb-32'}>
        <Link to='/login/' className='font-sans font-light'>Log in</Link>
      </div>
    </Layout>
  );
}

export default Page